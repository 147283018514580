<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card class="mb-5 filter-card-border" elevation="0">
          <v-card-title v-if="filtered" class="filter-card-banner">{{ $vuetify.lang.t('$vuetify.ilm.labels.filtersApplied') }}</v-card-title>
          <v-card-title v-else class="filter-card-banner">{{ $vuetify.lang.t('$vuetify.ilm.labels.noFiltersApplied') }}</v-card-title>
          <v-card-text v-if="filtered">
            <v-row v-if="dateRangePreview" class="mt-3">
              <v-col>
                <v-icon color="primary" class="mr-1">mdi-calendar</v-icon> {{ dateRangePreview }}
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col cols="6" v-if="sources">
                <filter-property-preview :label="$vuetify.lang.t('$vuetify.ilm.labels.source')" :items="sources"/>
              </v-col>
              <v-col cols="6" v-if="journals">
                <filter-property-preview :label="$vuetify.lang.t('$vuetify.ilm.labels.journal')" :items="journals"/>
              </v-col>
              <v-col cols="6" v-if="authorNames">
                <filter-property-preview :label="$vuetify.lang.t('$vuetify.ilm.labels.author')" :items="authorNames"/>
              </v-col>
              <v-col cols="6" v-if="articleTypes">
                <filter-property-preview :label="$vuetify.lang.t('$vuetify.ilm.labels.articleType')" :items="articleTypes"/>
              </v-col>
              <v-col cols="6" v-if="ontologyTags">
                <filter-property-preview :label="$vuetify.lang.t('$vuetify.ilm.labels.tag')" :items="ontologyTags"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <article-card class="mb-1" v-for="(article, index) in articles" :article="article" :full-article.sync="articles[index].fullArticle" :key="article.id"
                      mode="article" :get-full-article="getArticle" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Tools from '@/modules/common/utils/Tools.js'
import ProjectService from "@/modules/projects/services/ProjectService.js"

import ArticleCard from '@/modules/intelligentLiteratureMonitoring/components/ArticleCard.vue'
import FilterPropertyPreview from '@/modules/intelligentLiteratureMonitoring/components/FilterPropertyPreview.vue'


export default {

  name: 'ExportArticleList',

  components: { ArticleCard, FilterPropertyPreview },
  data () {
    return {
      articles: [],
      dateRangePreview: '',
      sources: [],
      journals: [],
      authorNames: [],
      articleTypes: [],
      ontologyTags: [],
      filtered: false
    }
  },
  methods: {
    searchArticles() {
      let filters = JSON.parse(this.$route.query.filters)
      this.dateRangePreview = Tools.getRageDatePreview(filters.publication_date_start, filters.publication_date_end)
      this.sources = filters.data_sources
      this.journals = filters.journals
      this.authorNames = filters.author_names
      this.articleTypes = filters.article_types
      this.ontologyTags = filters.ontology_tags
      this.filtered = Object.keys(filters).length > 1
      ProjectService.searchArticles(this.$route.params.projectId, filters).then(response => {
        this.articles = response.data.data
      })
    },
    getArticle(articleId) {
      return ProjectService.getArticle(this.$route.params.projectId, articleId)
    }
  },
  watch: {
    articles: {
      handler() {
        let result = true
        this.articles.forEach(article => {
          result = result && (article.fullArticle ? true : false)
        })
        if (result) {
          window.print()
        }
      },
      deep: true
    }
  },
  created() {
    this.searchArticles()
  }
}
</script>

<style lang="css" scoped>
  .filter-card-banner {
    background-color: #f4f4f4;
    border: 1px solid #cbcacb;
  }

  .filter-card-border {
    border: 1px solid #f5f4f5;
  }

</style>