<template>
  <div class="mb-3">
    <div class="fz-16 openSans-300 txt-primary-black " v-text="label"></div>
    <v-chip v-for="item in items" :key="item" class="mr-2 mt-2 openSans-400 fz-14 filter-chips" label>{{ item }}</v-chip>
  </div>
</template>

<script>
export default {

  name: 'FilterPropertyPreview',

  props: {
    label: String,
    items: Array
  }
}
</script>

<style lang="css" scoped>
</style>